import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { SearchModule } from './search/search.module';
import { GlobalState } from './state';
import { ApiModule } from './api/api.module';
import { AuthModule } from './auth/auth.module';
import { ChatModule } from './chat/chat.module';
import "@angular/compiler";
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@ngxs/storage-plugin";
import * as i3 from "./shared/shared.module";
export class AppModule {
    static ɵfac = function AppModule_Factory(t) { return new (t || AppModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [NgxsModule.forRoot([GlobalState], { developmentMode: !environment.production }),
            AuthModule,
            ApiModule,
            PagesModule,
            BrowserModule,
            BrowserAnimationsModule,
            AppRoutingModule,
            NgxsStoragePluginModule.forRoot({
                keys: '*',
            }),
            SearchModule,
            SharedModule.forRoot(),
            ChatModule] });
}
