import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrderService {
    http;
    constructor(http) {
        this.http = http;
    }
    searchOrders(ctx) {
        return this.http.get(`${environment.base}/order`, { params: API.makeParams(ctx) });
    }
    createOrder(order) {
        return this.http.post(`${environment.base}/order`, order);
    }
    patchOrder(order) {
        return this.http.patch(`${environment.base}/order`, order);
    }
    patchOrderItemTags(orderId, orderItemId, tags) {
        return this.http.patch(`${environment.base}/order/${orderId}/tags/${orderItemId}`, { tags });
    }
    patchOrderStatus(id, status) {
        return this.http.patch(`${environment.base}/order/${id}/status/${status}`, undefined);
    }
    readOrderById(id) {
        return this.http.get(`${environment.base}/order/${id}`);
    }
    findAssignedOrderUsers(id) {
        return this.http.get(`${environment.base}/order/assigned-users/${id}`);
    }
    setAssignedOrderUserWork(id) {
        return this.http.patch(`${environment.base}/order/assigned-users/set-work/${id}`, undefined);
    }
    createLabel(id, data) {
        return this.http.post(`${environment.base}/order/create-label/${id}`, data, {
            responseType: 'blob',
            observe: 'response'
        });
    }
    markWorkAsDone(id) {
        return this.http.patch(`${environment.base}/order/work-done/${id}`, undefined);
    }
    logTime(orderId, value) {
        return this.http.post(`${environment.base}/work/${orderId}`, value);
    }
    searchTimeLog(orderId, ctx) {
        return this.http.get(`${environment.base}/work/${orderId}`, { params: API.makeParams(ctx) });
    }
    removeTimeLog(id) {
        return this.http.delete(`${environment.base}/work/${id}`);
    }
    searchTimeLogForPartner(partnerId, ctx) {
        return this.http.get(`${environment.base}/work/partner/${partnerId}`, { params: API.makeParams(ctx) });
    }
    static ɵfac = function OrderService_Factory(t) { return new (t || OrderService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OrderService, factory: OrderService.ɵfac });
}
