import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FormsService {
    http;
    constructor(http) {
        this.http = http;
    }
    search(ctx) {
        return this.http.get(`${environment.base}/forms`, {
            params: API.makeParams(ctx),
        });
    }
    static ɵfac = function FormsService_Factory(t) { return new (t || FormsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FormsService, factory: FormsService.ɵfac });
}
