import * as __NgCli_bootstrap_1 from "@angular/platform-browser";
import { enableProdMode } from '@angular/core';
import localeSiExtra from '@angular/common/locales/extra/si';
import localeSi from '@angular/common/locales/si';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
if (environment.production) {
    enableProdMode();
}
registerLocaleData(localeSi, 'si-SL', localeSiExtra);
__NgCli_bootstrap_1.platformBrowser()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
