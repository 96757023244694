/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DocService {
    http;
    constructor(http) {
        this.http = http;
    }
    uploadFile(folderId, formData) {
        return this.http.post(`${environment.base}/doc/${folderId}`, formData);
    }
    getDoc(id) {
        return this.http.get(`${environment.base}/doc/download/${id}`, {
            responseType: 'blob',
            observe: 'response'
        });
    }
    static ɵfac = function DocService_Factory(t) { return new (t || DocService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DocService, factory: DocService.ɵfac });
}
