import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ISearchCtx, IApiResponse, IFolder, IDocPage, } from '@mvm/api-interfaces';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FoldersService {
    http;
    constructor(http) {
        this.http = http;
    }
    search(ctx) {
        return this.http.get(`${environment.base}/folders`, {
            params: API.makeParams(ctx),
        });
    }
    create(folder) {
        return this.http.post(`${environment.base}/folders`, folder);
    }
    searchFolderContents(folderId, ctx) {
        return this.http.get(`${environment.base}/doc/${folderId}`, {
            params: API.makeParams(ctx),
        });
    }
    static ɵfac = function FoldersService_Factory(t) { return new (t || FoldersService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FoldersService, factory: FoldersService.ɵfac });
}
